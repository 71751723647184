.example-image {
  width: 150px;
  height: 200px;
  border: 1px solid black;
  background-color: white;
}

.image-view {
  width: 33%;
  height: 100%;
  margin: 5px 0px;
  padding: 2px;
  object-fit: cover;
}

.image{
  display: block;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-container {
  position: relative;
  width: 100%;
}


.overlay-not-upload {
  position: absolute; 
  height: 100%;
  width: 100%;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  transition: .5s ease;
  opacity:1;
  color: white;
  font-size: 15px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.overlay-upload {
  position: absolute; 
  bottom: 0;
  width: 100%;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  transition: .5s ease;
  opacity:1;
  color: white;
  font-size: 15px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.card-container:hover .overlay {
  opacity: 1;
}


@media  (max-width: 640px) {
  .image-view {
    width: 50%;
    height: 100%;
  }

  .card-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

}

@media (max-width: 480px) {
  .image-view {
    width: 50%;
    height: 100%;
    padding: 2px;
  }
}

@media (max-width: 370px) {
  .image-view {
    width: 50%;
    height: 100%;
    padding: 2px;
  }
}

