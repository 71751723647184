@media only screen and (max-width: 767px) {
    .web-content {
        width: 80%;
      }
    .web-body {padding: 25px 16px; text-align: center;}
}
@media only screen and (min-width: 768px) {
    .web-content {
        width: 35%;
      }
      .web-body {padding: 50px 16px; text-align: center;}
}

/* The Modal (background) */
.webcam-alert {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  /* Modal Content */
  .web-content {
    position: relative;
    background-color: white;
    margin: auto;
    padding: 0;
    border: 1px solid black;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetops;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetops;
    animation-duration: 0.4s
  }
  
  /* Add Animation */
  @-webkit-keyframes animatetops {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  
  @keyframes animatetops {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  
  .web-header {
    justify-content: center;
    padding: 7px 16px;
    background-color: #f1ce6e;
    color: black;
    font-weight: bold;
  }
  
  .web-footer {
    justify-content: center;
    padding: 7px 16px;
    background-color: white;
    color: black;
  }
  .accept-web-btn {border-radius: 5px; background-color: #f1ce6e; padding: 8px; padding-left: 18px; padding-right: 18px;}