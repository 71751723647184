.prefixlabel {
  width: 340px;
}

.thailabel {
  font-size: 0.6rem;
  font-weight: lighter;
}

@media (max-width: 768px) {
  .prefixlabel {
    width: 100%;
  }
}