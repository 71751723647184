:root{
    --theme-color: rgb(255, 176, 7);
    --theme-secondary: rgba(255, 176, 7, 0.5);
}

.head-title{
    color: var(--theme-color);
}

body{
    background-color: var(--theme-secondary); 
}

.theme-button{
    background-color: var(--theme-color);
    border: 1px solid var(--theme-color);
    color: #fff;
}
.theme-button:hover{
    background-color: var(--theme-color);
    opacity: 90%;
    color: #fff;
    border: 1px solid var(--theme-color);
}
.btn-main{
    width: 100%;
}
.btn-manaual{
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
    filter: invert(100%);
}
.btn-manaual:hover{
    background-color: var(--theme-color);
    color: #fff !important;
}