code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/prompt/Prompt-Regular.ttf');
}
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/sarabun/Sarabun-Regular.ttf');
}
body {
  font-family: 'Prompt';
}
h1, h2, h3, h4, h5{
  font-family: 'Prompt';
}
