.image-view {
    width: 33%;
    height: 100%;
    margin: 5px 0px;
    padding: 2px;
    object-fit: cover;
  }

  .image{
    display: block;
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  

  @media  (max-width: 640px) {
    .image-view {
      width: 50%;
      height: 100%;
      object-fit: cover;
    }
  
    img{
        display: flex;
        object-fit: cover;
    }

    .card-container {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  
  }
  
  @media (max-width: 480px) {
    .image-view {
      width: 50%;
      height: 100%;
      padding: 2px;    
    }
  }