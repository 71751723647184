ol,
ul {
  list-style: square;
  list-style-position: inside;
  margin-left: 5%;
  padding: 0;
}

#box{
    max-height: calc(100vh - 20px);
    overflow-y: auto;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
}
@media only screen and (max-width: 767px) {
    #policyText h1{
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    #policyText h2{
        font-size: 16px;
        font-weight: bold;
    }
    #policyText p, #policyText ul{
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 20px;
    }
    #box {
        border-radius: 20px;
        width: 95%;
        max-width: 375px;
        height: 500px;
        background-color: #ffffff;
        display: flexbox;
     }
     #topbox{
        font-size: 19px;
     }
     #bottombox{
        padding: 0.5em;
    }
    #agree button{
        margin-top: 3px;
    }
    #bottombox p{
        font-size: 14px;
    }
    #bottombox span{
       font-size: 11px;
    }
}

@media only screen and (min-width: 768px) {
    #policyText h1{
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    #policyText h2{
        font-size: 22px;
        font-weight: bold;
    }
    #policyText p, #policyText ul{
        font-size: 16px;
        margin-top: 5px;
        margin-bottom: 20px;
    }
    #box{
        border-radius: 20px;
        width: 50%;
        /*max-width: 375px;*/
        height: 500px;
        background-color: #ffffff;
        display: flexbox;
    }
    #topbox{
        font-size: 25px; 
    }
    #bottombox{
        padding: 0.3em;
    }
    #agree button{
        margin-top: 7px;
    }
    #bottombox p{
        margin-top: 1%;
        font-size: 16px;
    }
    #bottombox span{
       font-size: 14px;
    }
}

#topbox{
    text-align: center;
    background-color: #404040;
    border-radius: 10px 10px 0px 0px;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    color: white;
}
#middlebox{
    height: 320px;
    overflow-y: scroll;
    padding: 2em;
    background-color: #e2e2e2;
}
#bottombox{
   background-color: #404040;
   border-radius: 0 0 10px 10px;
   height: 130px;
}
#bottombox p{
    text-align: center;
    font-weight: bold;
    line-height: 30px;
    color: white;
}
#bottombox span{
   text-align: center;
   color: white;
}
#agree{
    text-align: center;
}
#agree button{
    background-color: white;
    padding: 0.25em;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
}
#check{
    text-align: center;
}