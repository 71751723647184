@media  (max-width: 640px) {
    video {
        width: 100%;
        height: 390px;
    }
}
  
  @media (max-width: 500px) {
    video {
        width: 100%;
        height: 380px;
    }
  }
  
  @media (max-width: 389px) {
    video {
        width: 100%;
        height: 300px;
    }
  }